import React from "react"
import Layout from "../components/layout"

const ContactPage = ({ pageContext }) => (
  <Layout title="About" navItems={pageContext.categories}>
    <div className="uk-section-xsmall">
      <div className="uk-container uk-container-large uk-text-left">
        <h1 className="uk-text-center">About Us</h1>
        <p>&nbsp;</p>
        <p>
          myVRVdrive.com is owned and operated by Daikin’s largest VRV
          representative in North America: DXS North America (Direct Expansion
          Solutions). Founded in Texas in 2007, DXS is a specialized VRV rep
          firm focused exclusively on supplying and supporting Daikin VRV and
          air source heat pump split systems, including complementary technology
          for ventilation and controls. DXS now also operates out of Ontario,
          Canada and New England (Massachusetts, New Hampshire and Rhode
          Island). You can learn more about DXS{" "}
          <a href="https://dxseng.com" target="_blank">
            here
          </a>
          .
        </p>

        <p>
          Daikin North America, or any of its affiliate companies, does not own
          or operate this website, nor is the information and data on this
          website guaranteed or officially approved by Daikin. Please refer to{" "}
          <a href="/info">official Daikin websites</a> for official information,
          and work with your local{" "}
          <a href="/support">Daikin manufacturer’s representatives</a> for
          selections.
        </p>

        <p>
          The purpose of this site is to provide well organized preliminary
          information on Daikin VRV and split systems for your projects. We
          strongly encourage always working with your local Daikin
          manufacturer’s representatives to get official selections using Daikin
          software, as well as submittal information and documents, and finally
          for any pricing, procurement and start-up by factory approved
          technicians.
        </p>

        <p>
          Please <a href="/contact">contact us</a> for any questions or
          feedback. We welcome all suggestions for improving VRVdrive, and ideas
          on how this platform can make your work with Daikin VRV equipment
          easier!
        </p>
      </div>
    </div>
  </Layout>
)

export default ContactPage
